export const firebaseConfig = {
  apiKey: "AIzaSyCQsPonwroVSglMNabvV2FeqEWKsXSzaMk",
  authDomain: "sociilaw-website.firebaseapp.com",
  projectId: "sociilaw-website",
  storageBucket: "sociilaw-website.appspot.com",
  messagingSenderId: "329026325088",
  appId: "1:329026325088:web:1626204a181862f114d105",
  measurementId: "G-9L0T5GC0XR",
};

export const appFirebaseConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_ID,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
};
