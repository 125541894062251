import React from "react";
import { useEffect } from "react";
import { WHATSAPP_EDGAR } from "../../../constants/routes";

export default function EdgarPage() {
  useEffect(() => {
    window.location.href = WHATSAPP_EDGAR;
  }, []);

  return <div></div>;
}
