import React, { createContext } from "react";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp, getApps } from "firebase/app";
// Add the Firebase products that you want to use
import { getAnalytics } from "firebase/analytics";

import { appFirebaseConfig, firebaseConfig } from "./firebaseConfig";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

const FirebaseContext = createContext(null);

export { FirebaseContext };

const FirebaseProvider = ({ children }) => {
  let firebaseObj = {
    app: null,
    analytics: null,
    db: null,
  };

  if (!getApps().length) {
    const site = initializeApp(firebaseConfig);
    const analytics = getAnalytics(site);

    const app = initializeApp(appFirebaseConfig, "app");

    const db = getFirestore(app);
    if (window.location.hostname === "localhost") {
      connectFirestoreEmulator(db, "localhost", 8080);
    }

    firebaseObj = {
      app: site,
      analytics,
      db,
    };
  }

  return (
    <FirebaseContext.Provider value={firebaseObj}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
