import React from "react";
import { Snackbar, makeStyles, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DefaultButton from "../Buttons/DefaultButton";

const useStyles = makeStyles({
  alert: {
    borderRadius: 10,
    boxShadow: "-1px 1px 10px 0px",
  },
});
export default function CookiesAlert({ open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Snackbar open={open} className="zIndexUp">
      <Alert
        severity="warning"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="30px"
            viewBox="0 0 24 24"
            width="30px"
            fill={theme.palette.primary.main}
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g>
                <circle cx="10.5" cy="8.5" r="1.5" />
                <circle cx="8.5" cy="13.5" r="1.5" />
                <circle cx="15" cy="15" r="1" />
                <path d="M21.95,10.99c-1.79-0.03-3.7-1.95-2.68-4.22c-2.97,1-5.78-1.59-5.19-4.56C7.11,0.74,2,6.41,2,12c0,5.52,4.48,10,10,10 C17.89,22,22.54,16.92,21.95,10.99z M12,20c-4.41,0-8-3.59-8-8c0-3.31,2.73-8.18,8.08-8.02c0.42,2.54,2.44,4.56,4.99,4.94 c0.07,0.36,0.52,2.55,2.92,3.63C19.7,16.86,16.06,20,12,20z" />
              </g>
            </g>
          </svg>
        }
        action={
          <DefaultButton
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Entendi!
          </DefaultButton>
        }
        className={classes.alert}
      >
        Nós utilizamos cookies para garantir a melhor experiência possível.
      </Alert>
    </Snackbar>
  );
}
