import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

import { makeStyles, Drawer, Grid, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import LocalPlayOutlinedIcon from "@material-ui/icons/LocalPlayOutlined";
import { Storefront } from "@material-ui/icons";

import DefaultButton from "../Buttons/DefaultButton";
import SquareButton from "../Buttons/SquareButton";
import SociiLogo from "../../Images/sociiLogo.svg";

import { getAnalytics, logEvent } from "firebase/analytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "35px 0 0 35px",
    padding: "1rem 1.5rem 3rem 1.5rem",
    background: theme.palette.bg,
    maxWidth: 330,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "70vw",
    },
  },
  gridContainer: {
    height: "100%",
  },
  logo: {
    width: "100%",
    maxWidth: 100,
  },
  gridBtns: {
    maxWidth: 200,
  },
}));

export default function MenuDrawer(props) {
  const { open, onClose } = props;

  const classes = useStyles();

  const handleClose = (event) => {
    handleLogEvent(event);
    onClose();
  };

  const handleLogEvent = (event) => {
    const analytics = getAnalytics();
    logEvent(analytics, event);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => handleClose("close")}
      transitionDuration={400}
      classes={{
        root: "zIndexUp",
        paper: classes.paper,
      }}
    >
      <Grid
        container
        justifyContent="center"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <IconButton onClick={() => handleClose("click_drawer_close")}>
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <IconButton
              component={Link}
              to={ROUTES.HOME}
              onClick={() => handleClose("click_drawer_p1")}
            >
              <img src={SociiLogo} width={75} alt="Socii Logo" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          className={classes.gridBtns}
          container
          spacing={2}
          alignContent="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            container
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <SquareButton
                href={ROUTES.INSTITUTIONAL}
                onClick={() => handleClose("click_drawer_p2")}
                text="Institucional"
                icon={<BusinessIcon fontSize="large" color="primary" />}
              />
            </Grid>
            <Grid item xs={6}>
              <SquareButton
                href={ROUTES.FEATURES}
                onClick={() => handleClose("click_drawer_p3")}
                text="Features"
                icon={
                  <OfflineBoltOutlinedIcon fontSize="large" color="primary" />
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            {/* <SquareButton
              href={ROUTES.SOCII_PLUS}
              text="Socii+"
              icon={<LocalPlayOutlinedIcon fontSize="large" color="primary" />}
              onClick={() => handleClose("click_drawer_p4")}
            /> */}
            <SquareButton
              href={ROUTES.ESPECIALISTS}
              onClick={() => handleClose("click_drawer_p5")}
              text="Especialistas"
              icon={
                <SupervisedUserCircleIcon fontSize="large" color="primary" />
              }
            />
          </Grid>
          {/* <Grid item xs={12} container justifyContent="center">
            <SquareButton
              href={ROUTES.PLANS}
              onClick={() => handleClose("click_drawer_p6")}
              text="Planos"
              icon={<Storefront fontSize="large" color="primary" />}
            />
          </Grid> */}
        </Grid>

        <Grid item xs={12}>
          <DefaultButton
            isBigger={true}
            variant="contained"
            color="primary"
            href={ROUTES.SIGN_IN_APP}
            onClick={() => handleClose("click_drawer_log")}
            style={{ marginBottom: ".75rem" }}
          >
            Entrar
          </DefaultButton>
          <DefaultButton
            isBigger={true}
            variant="outlined"
            color="primary"
            onClick={() => handleClose("click_drawer_sign")}
            href={ROUTES.INTRO_APP}
          >
            Registrar
          </DefaultButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
