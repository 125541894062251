import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CookiesProvider } from "react-cookie";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import FirebaseProvider from "./firebase";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6600",
      light: "#FF9966",
      dark: "#CC3300",
      done: "#00CC99",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      grey: "#666666",
      lightGrey: "#CCCCCC",
      darkGrey: "#333333",
      contrastText: "#FF6600",
    },
    face: "#4267B2",
    google: "#5093F1",
    bg: "#FDFAF7",
    error: {
      main: "#CC3300",
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <CookiesProvider>
      <FirebaseProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </FirebaseProvider>
    </CookiesProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.unregister();
