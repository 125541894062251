import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  fab: {
    width: 67,
    height: 67,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 15,
  },
  text: {
    marginTop: ".4rem",
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  disabled: {
    opacity: 0.6,
  },
}));

const SquareButton = (props) => {
  const classes = useStyles(props);

  const { text, icon, component, disabled, href, onClick } = props;

  return (
    <div
      className={`${classes.root} square-btn ${
        disabled ? classes.disabled : null
      }`}
      {...props}
    >
      <Fab
        onClick={onClick}
        className={classes.fab}
        component={component === "span" ? "span" : Link}
        to={component === "span" ? undefined : href}
        disabled={disabled}
      >
        {icon}
      </Fab>
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default SquareButton;
