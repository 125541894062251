import React from "react";

import {
  makeStyles,
  Button,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: (props) => props.radius || 10,
    padding: "6px 19px",
  },
  label: {
    textTransform: "none",
    fontSize: (props) => props.fontSize || "1rem",
    fontWeight: "normal",
    height: (props) => props.height || 25,
  },
  isBigger: {
    height: "35px !important",
    fontSize: "1.2rem !important",
  },
  outlined: {
    // borderWidth: 2,
  },
  disabled: {},
}));

const themeGreen = createTheme({
  palette: {
    primary: {
      main: "#00CC99",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const DefaultButton = (props) => {
  const classes = useStyles(props);

  const { variant, color, href, isBigger, children, isGreen } = props;

  const component = (
    <Button
      variant={variant}
      href={href}
      color={color}
      disabled={props.disabled}
      classes={{
        root: classes.root,
        label: `${classes.label} ${isBigger ? classes.isBigger : null}`,
        disabled: classes.disabled,
        outlined: classes.outlined,
      }}
      {...props}
    >
      {children}
    </Button>
  );

  return isGreen ? (
    <ThemeProvider theme={themeGreen}>{component}</ThemeProvider>
  ) : (
    component
  );
};

export default DefaultButton;
